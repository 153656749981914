@font-face {
  font-family: montserrat;
  src: url(./assets/fonts/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: lato;
  src: url(./assets/fonts/Lato-Regular.ttf);
}
*{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-weight: normal;
}
body{
  background-color: #231f20;
  color: #f6f4f6;
  scroll-behavior: smooth;
}
h1{
  font-size: xx-large;
  margin-bottom: 10px;
  font-family: 'montserrat';
}
h2{
  margin-bottom: 10px;
  font-family: 'montserrat';
}
h3,p{
  margin-bottom: 10px;
  font-family: 'lato';
  line-height: 1.2;
}b{
  font-weight: bold;
}
