.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #231f20;
  border-bottom: 1px inset;
  border-image: linear-gradient(to right, #c88a34, #ebc350, #ebc350, #c88a34, #ebc350) 1;
  padding: 10px 20px;
  height: 5vh;
}

.navbar-logo a, h2 {
  color: #f6f4f6;
  text-decoration: none;
}

.nav-links {
  display: flex;
  list-style: none;
}

.nav-links button {
  background: none;
  border: none;
  color: #f6f4f6;
  text-decoration: none;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
}

.nav-links button:hover {
  color: #ebc350;
  transition: color 0.3s ease-in;
  border-radius: 3em;
}

.img-logo {
  height: 2em;
  border-radius: 0.2em;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 3px 0;
}

@media (max-width: 768px) {
  .nav-links {
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background-color: #231f20;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .nav-links.open {
    display: flex;
    opacity: 1;
  }

  .hamburger {
    display: flex;
  }

  .nav-links button {
    padding: 15px;
    font-size: 1.2rem;
  }
}
