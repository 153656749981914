.AboutContainer{
    display: grid;
    grid-template-columns: 60% 40%;
    height: max-content;
    min-height: 60vh;
    align-content: center;
    padding: 10px;
}
.AboutText{
    margin:auto;
}

.ContactInfo{
    margin: auto;
    width: 100%;
    height: 100%;
    & > :is(h2, h3){
        color: #f6f4f6;
        margin-left: 3rem;
    }
    & > h3 > a{
        text-decoration: none;
        color: #f6f4f6;
        &:hover{
            color: #eab350;
            transition: all 0.3s ease-in;
        }
    }
}
.AboutText > :is(h2, h3){
    margin-left: 3rem;
    text-align: left;
    color: #f6f4f6;
}
@media screen and (max-width:768px) {
    .AboutContainer, .ContactInfo{
        display: flex;
        padding: 5px;
        margin: 10% auto;
        flex-direction: column;
        &> :is(h2, h3){
            margin: auto;
            padding: 2px 2rem;
            max-width: 45rem;
            text-align: left;
            color: #f6f4f6;
            font-weight: bolder;
        }
    }
}