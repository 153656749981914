form{
    display: flex;
    flex-direction: column;
    border: 1px solid;
    border-image: linear-gradient(to right,#ebc350, #c88a34, #ebc350, #ebc350, #c88a34)1;
    padding: 5% 10px;
    margin: 10px;
}
label{
    margin-left: 2px;
    padding: 10px;
    font: lato;
    font-family: 'lato';
    line-height: 1.2;
}
input, textarea {
    border-radius: 5px;
    padding: 7px;
    width: 100%;
    resize: none;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input:focus, textarea:focus {
    outline: none;
    border-color: #ebc350;
    box-shadow: 0 0 5px #ebc350;
}
form > button{
    align-self: center;
    margin: 20px 10px 0 10px;
    padding: 5px 10px 5px;
    width: max-content;
    color: #f6f4f6;
    font-weight: bold;
    font-size: 1rem;
    background-color: #231f20;
    border: none;
}