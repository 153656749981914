.CardAltContainer{
    padding: 10px;
    margin: 5% auto auto 3em;
    place-content: center;
    max-width: 45em;
}

@media screen and (max-width:768px){
    .CardAltContainer{
        margin: 5rem auto;    
        &> :is(h2, h3){
            padding: 2px 2rem;
            max-width: 45rem;
            text-align: left;
            color: #f6f4f6;
            font-weight: bolder;
        }
    }

}