.Hero_Container{
    min-height: 95vh;
    height: max-content;
    background-image: url('../image/bg0.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;
    padding: 10px;
    border-bottom: 1px solid;
    border-image: linear-gradient(to right,#ebc350, #c88a34, #ebc350, #ebc350, #c88a34)1;
}
.Hero_Holder{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: start;
    width: fit-content;
    margin: 3em;
}
.Hero_Holder > :is(h2, h1){
    padding: 15px;
    width: 50rem;
    max-width: 100%;
    color: #f6f4f6;
}
.Hero_Logo{
    object-fit: cover;
    width: 30rem;
    border-radius: 2em;
}

@media screen and (max-width:768px){
    .Hero_Holder{
        background-color: transparent;
        border: none;
    }
    .Hero_Logo{
        width: 100%;
    }
    .Hero_Holder > :is(h2,h1){
        width: auto;
        padding: 0;
        text-align: center;
    }
}