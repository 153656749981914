::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #141213; 
    border-radius: 5px;
  }
   
  ::-webkit-scrollbar-thumb {
    background: #ebc350; 
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #c88a34; 
  }