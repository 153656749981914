.CardHolder{
    display: flex;
    flex-direction: column;
    place-content: center;
    min-height: max-content;
    border-bottom: 1px solid;
    border-image: linear-gradient(to right, #c88a34, #ebc350, #ebc350, #c88a34, #ebc350) 1;
    padding: 10px;
}

.SelectedCardView{
    display: flex;
    flex-direction: column;
    place-content: center;
    padding: 10px;
    margin: 5% 3em 2em 3em;
    padding: 10px;
    & > h3{
        max-width: 60%;
        padding: 10px;
    }
    & > h1{
        font-size: 4rem;
        max-width: 100%;
    }
}
.CloseButton{
    color:#f6f4f6;
    background-color: #231f20;
    font-size: larger;
    margin: 2px;
    padding: 10px;
    border: none;
    width: max-content;
    text-align: start;
    cursor: pointer;
}
.CloseButton:hover{
    color: #ebc350;
    transition: color 0.8s;
}


.CardHolder .Card_Container {
    display: grid;
    grid-template-columns: repeat(6, 15.5%);
    margin: auto auto 5% auto;
    gap: 1em;
    padding: 1em;
    max-width: 100vw;
    height: auto;
    justify-content: center;
}

.CardHolder .Card_Container .Card {
    background-color: #231f20;
    padding: 0.5em;
    border: 1px solid #413c3d;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: auto;
    box-sizing: border-box;
    height: 100%;
    transition: all 0.3s ease-in-out;
    word-wrap: break-word;
    cursor: pointer;
}

.CardHolder .Card_Container .Card:hover {
    border-image: linear-gradient(to right, #ebc350, #c88a34, #ebc350, #ebc350, #c88a34) 1;
    transition: all 0.8s ease;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

.CardHolder .Icon {
    width: 100%;
    margin-bottom: 10px;
}

.CardHolder .Icon img {
    width: 100%;
    object-fit: cover;
}

.CardHolder .TextBox {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
}

.CardHolder {
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.CardHolder.animate-out {
    transform: translateY(-20px);
    opacity: 0;
}

.CardHolder.animate-in {
    transform: translateY(20px);
    opacity: 0;
}

@media screen and (max-width:768px) {
    .CardHolder{
        display: block;
        max-width: auto;
    }
    .CardHolder .Card_Container {
        display: flex;
        flex-direction: column;
        gap: 0;
        padding: 20px;
        margin: auto;
        height: auto;
        width: fit-content;
        position: relative;
        place-content: center;
    }
    .CardHolder .Card_Container .Card{
        flex-direction: row;
        min-height: auto;
        gap: 10px;
        margin-top: 0;
    }
    .CardHolder .Icon{
        margin: auto;
        padding: 0;
    }
    .CardHolder .Icon img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
}
